<div style="overflow-x:hidden;">
    <section>
        <app-banner></app-banner>
    </section>
    <section id="sobre">
        <app-sobre></app-sobre>
    </section>
    <section id="servicos">
        <app-servicos></app-servicos>
    </section>
    <section id="galeria">
        <app-galeria></app-galeria>
    </section>
    <section id="contato">
        <app-contato></app-contato>
    </section>
    <section>
        <app-rodape></app-rodape>
    </section>

</div>