<div class="pagina-servicos">
   
    <div class="row">
        <div class="col-12 mt-5 mb-4 titulo">
            <h2>SEMPRE INOVANDO</h2>
        </div>
    </div>

    <div style="width: 50px; margin: auto;">
        <app-divisao></app-divisao>
    </div>

    <div class="row">
        <div class="col-12 mt-4 mb-4 sub-titulo">
            <span>Conheça nossas áreas de atuação:</span>
        </div>
    </div>

    <div class="row mt-3 ml-3 mr-3 servicos">
        <div class="col-sm-4 col-md-6 col-lg-3 servico">
            <img src="assets/img/civil.png" alt="icone contrução civil">
            <h4>CONSTRUÇÃO CIVIL</h4>
            <span>
                Projetos e construções de novas edificações, manutenção hidráulica, estrutural, coberturas, pinturas e
                reformas em geral.
            </span>
        </div>
        <div class="col-sm-4 col-md-6 col-lg-3 servico">
            <img src="assets/img/eletrica.png" alt="icone elétrica">
            <h4>ELÉTRICA</h4>
            <span>
                Novas instalações, dimensionamentos elétricos em geral, sistemas de proteção contra raios com laudo,
                montagem e instalação de painéis de melhores, entre outros serviços.
            </span>
        </div>
        <div class="col-sm-4 col-md-6 col-lg-3 servico">
            <img src="assets/img/solar.png" alt="icone energia solar">
            <h4>ENERGIA SOLAR</h4>
            <span>Projeto, dimensionamento e instalação de geradores solar (on-grid, off-grid, zero grid), Instalações
                de geradores para captação de energia solar e homologação em sistemas on-grid.</span>
        </div>
        <div class="col-sm-4 col-md-6 col-lg-3 servico">
            <img src="assets/img/tercerizacao.png" alt="icone tercerizacao">
            <h4>TERCERIZAÇÃO</h4>
            <span>Fornecemos mão de obra qualificada para prestação de serviços de instalações elétricas e manutenção
                predial para Indústrias.</span>
        </div>
   
    </div>

</div>