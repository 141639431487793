<div class="sobre">

    <div class="header">
        <app-divisao></app-divisao>
        <div class="row row-no-padding">
            <div class="col-12" class="titulo">
                <h4>SOMOS A <span style="color: #F7B40E;">BOVA NOVA</span> <br> ENGENHARIA</h4>
            </div>
        </div>
    </div>


    <div class="row row-no-padding mt-3">
        <div class="col-sm-12 col-md-6 col-lg-6 mb-5 img">
            <img src="assets/img/construction.png" alt="Imagem de contrução">
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6">
            <div style="padding: 0px 60px;">
                <h5 class="mb-4">SOBRE NÓS</h5>

                <app-divisao></app-divisao>

                <div class="mt-4">
                    <span><strong>Uma empresa comprometida <br> com a inovação</strong></span>

                    <div class="mt-3 mb-5 texto">
                        <p>Desde 2001, A Boa Nova Engenharia, desenvolve seus projetos com comprometimento e inovação, garantindo satisfação total dos clientes. Realiza obras de alta, média e pequena complexidade, com máxima agilidade, segurança e eficiência.
                            <p>A cada projeto, renova-se o compromisso de sempre proporcionar soluções eficientes aos clientes por meio da garantia do alto padrão de qualidade e atendimento aos prazos.</p>
                            <p>O time BN possui corpo técnico e administrativo altamente qualificado, que visa a melhoria contínua de seus processos, gerando soluções diferenciadas aos clientes.</p>
                    </div>

                </div>
            </div>


        </div>
    </div>

</div>