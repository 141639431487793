<div class="rodape">

    <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="icones">
                <a [href]="linkEmail" target="_blank"><img src="assets/img/email.png" alt="icone e-mail"></a>
                <a [href]="linkInsta" target="_blank"><img src="assets/img/instagram.png" alt="icone instagram"></a>
                <a [href]="linkWA" target="_blank"><img src="assets/img/whatsapp-peq.png" alt="icone whatsapp"></a>
            </div>
        </div>

        <div class="col-lg-2 col-md-2 col-md-12">
            <div class="logo">
                <img class="mb-3" src="assets/img/logo.png"><br>
                <span>BOA NOVA</span>
            </div>
        </div>

        <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="texto">
                <span>Copyright © 2021. BOA NOVA ENGENHARIA . Todos os direitos reservados.</span>
            </div>
        </div>


    </div>