import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divisao',
  templateUrl: './divisao.component.html',
  styleUrls: ['./divisao.component.css']
})
export class DivisaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
